import { IMAGE_CONFIG } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors, withJsonpSupport } from '@angular/common/http';
import {
  importProvidersFrom,
  inject,
  isDevMode,
  LOCALE_ID,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';
import { bootstrapApplication, DomSanitizer } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { firstValueFrom } from 'rxjs';
import { register as registerSwiper } from 'swiper/element';

import { AdvertisementsEffects } from './app/advertisements/advertisements.effects';
import { advertisementsFeature } from './app/advertisements/advertisements.feature';
import { Configuration } from './app/api';
import { AppComponent } from './app/app.component';
import { routes } from './app/app-routing';
import { AuthenticationService } from './app/auth/authentication.service';
import { CitiesEffects } from './app/cities/cities.effects';
import { citiesFeature } from './app/cities/cities.feature';
import { apiConfiguration } from './app/credentials';
import {
  DEFAULT_LANGUAGE,
  httpLanguageInterceptor,
  LanguageService,
  SUPPORTED_LANGUAGES,
  TranslocoHttpLoader,
} from './app/locales';
import { CustomTitleStrategy } from './app/title-strategy';
import { environment } from './environments/environment';

registerSwiper();

function initApplication(
  languageService: LanguageService,
  translocoService: TranslocoService,
  _: AuthenticationService, // it just needs to be loaded so the constructor effects trigger
  iconRegistry: MatIconRegistry,
  sanitizer: DomSanitizer,
) {
  return () => {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    iconRegistry.addSvgIconResolver((name, namespace) => {
      if (namespace === '') {
        return sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`);
      }
      console.error(`Unknown icon: ${namespace} ${name}`);
      return null;
    });
    const lang = languageService.language();
    translocoService.setActiveLang(lang);
    const promises = [firstValueFrom(translocoService.load(lang))];
    return Promise.all(promises);
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideTransloco({
      config: {
        availableLangs: SUPPORTED_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: LanguageService) => languageService.getCurrentLocale(),
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      // setPersistence(auth, indexedDBLocalPersistence);
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    importProvidersFrom(MatDialogModule),
    { provide: Configuration, useFactory: () => apiConfiguration },
    provideAppInitializer(() => {
      const initializerFn = initApplication(
        inject(LanguageService),
        inject(TranslocoService),
        inject(AuthenticationService),
        inject(MatIconRegistry),
        inject(DomSanitizer),
      );
      return initializerFn();
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        showFirstLastButtons: true,
        pageSizeOptions: [10, 20, 50, 100, 200, 500],
        pageSize: 50,
      } satisfies MatPaginatorDefaultOptions,
    },
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    provideAnimations(),
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' }), withComponentInputBinding()),
    provideHttpClient(withFetch(), withInterceptors([httpLanguageInterceptor]), withJsonpSupport()),
    provideStore({
      [citiesFeature.name]: citiesFeature.reducer,
      [advertisementsFeature.name]: advertisementsFeature.reducer,
    }),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
    provideEffects(CitiesEffects, AdvertisementsEffects),
    provideExperimentalZonelessChangeDetection(),
    { provide: IMAGE_CONFIG, useValue: { disableImageSizeWarning: true, disableImageLazyLoadWarning: true } },
  ],
}).catch((err) => console.error(err));
