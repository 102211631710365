import { inject } from '@angular/core';
import { Auth, ParsedToken, user } from '@angular/fire/auth';
import { AuthPipe, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { first, map, pipe } from 'rxjs';

import { AuthRole } from './api';
import { AuthenticationService } from './auth/authentication.service';

export const redirectUnauthorizedToLogin = (_: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): AuthPipe => {
  AuthenticationService.redirectAfterLogin = routerState.url;
  return redirectUnauthorizedTo(['/login']);
};
export const redirectLoggedInHome = () => redirectLoggedInTo(['/']);

export const adminGuard: CanActivateFn = () => {
  const auth = inject(AuthenticationService);
  const router = inject(Router);
  return auth.isAdmin() ? true : router.createUrlTree(['/']);
};

export const hasAdminClaims = (snapshot: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) =>
  pipe(
    // @ts-expect-error the type of this function is stupid
    customClaims,
    map((claims: ParsedToken) =>
      ((claims['roles'] ?? []) as AuthRole[]).includes(AuthRole.ADMIN)
        ? true
        : redirectUnauthorizedToLogin(snapshot, routerState),
    ),
  );

export const verifyEmailGuard: CanActivateFn = () => {
  const router = inject(Router);
  return user(inject(Auth)).pipe(
    first(),
    map((user) => {
      if (!user || user.emailVerified) {
        return true;
      } else {
        return router.createUrlTree(['/verify-email']);
      }
    }),
  );
};

export const redirectVerifiedEmailToHome: CanActivateFn = () => {
  const router = inject(Router);
  return user(inject(Auth)).pipe(
    first(),
    map((user) => {
      return !user || user.emailVerified ? router.createUrlTree(['/']) : true;
    }),
  );
};
