import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs';

import { AdvertisementsService } from '../api/api/advertisements.service';
import { ErrorService } from '../shared/error.service';
import {
  SimpleDialogComponent,
  SimpleDialogData,
  SimpleDialogResult,
} from '../shared/simple-dialog/simple-dialog.component';
import {
  getAdvertisement,
  getAdvertisementFailed,
  getAdvertisementSuccess,
  listAdvertisementSuggestions,
  listAdvertisementSuggestionsFailed,
  listAdvertisementSuggestionsSuccess,
  searchAdvertisements,
  searchAdvertisementsFailed,
  searchAdvertisementsSuccess,
  sendContactRequest,
  sendContactRequestFailed,
  sendContactRequestSuccess,
} from './advertisements.actions';

@Injectable({ providedIn: 'root' })
export class AdvertisementsEffects {
  private advertisementsService = inject(AdvertisementsService);
  private actions$ = inject(Actions);
  private matDialog = inject(MatDialog);
  private transloco = inject(TranslocoService);
  private errorService = inject(ErrorService);

  listAdvertisementSuggestions = createEffect(() =>
    this.actions$.pipe(
      ofType(listAdvertisementSuggestions),
      switchMap((action) =>
        this.advertisementsService.listAdvertisementSuggestions().pipe(
          map((payload) => listAdvertisementSuggestionsSuccess({ payload })),
          catchError((error) => this.errorService.handleError(action, listAdvertisementSuggestionsFailed, error)),
        ),
      ),
    ),
  );

  searchAdvertisements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchAdvertisements),
      switchMap((action) =>
        this.advertisementsService
          .searchAdvertisements(
            action.params.query,
            action.params.countryCode,
            action.params.regionId,
            action.params.cityId,
            action.params.categoryIds,
            action.params.maxPrice,
            action.params.page,
            action.params.pageSize,
            action.params.suggestionCount,
          )
          .pipe(
            map((payload) => searchAdvertisementsSuccess({ payload })),
            catchError((error) => this.errorService.handleError(action, searchAdvertisementsFailed, error)),
          ),
      ),
    );
  });

  getAdvertisement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAdvertisement),
      switchMap((action) =>
        this.advertisementsService.getAdvertisement(action.advertisementId).pipe(
          map((payload) => getAdvertisementSuccess({ payload })),
          catchError((error) => this.errorService.handleError(action, getAdvertisementFailed, error)),
        ),
      ),
    );
  });

  sendContactRequest = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendContactRequest),
      switchMap((action) =>
        this.advertisementsService.sendAdvertisementContactRequest(action.advertisementId, action.payload).pipe(
          map(() => sendContactRequestSuccess()),
          tap(() =>
            this.matDialog.open<SimpleDialogComponent, SimpleDialogData, SimpleDialogResult>(SimpleDialogComponent, {
              data: {
                ok: this.transloco.translate('OK'),
                message: this.transloco.translate('MESSAGE_SENT_TO_SELLER'),
              },
            }),
          ),
          // TODO redirect to confirmation page
          catchError((error) => this.errorService.handleError(action, sendContactRequestFailed, error)),
        ),
      ),
    );
  });
}
