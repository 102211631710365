import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, take } from 'rxjs';

const defaultPageTitle = 'HalloHoreca!';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
  title$ = new BehaviorSubject<string>(defaultPageTitle);
  private title = inject(Title);
  private transloco = inject(TranslocoService);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.transloco
        .selectTranslate(title)
        .pipe(take(1))
        .subscribe((translatedTitle) => {
          this.title$.next(`${translatedTitle} - ${defaultPageTitle}`);
          this.title.setTitle(this.title$.value);
        });
    } else {
      this.title$.next(defaultPageTitle);
      this.title.setTitle(this.title$.value);
    }
  }
}
