<ng-container *transloco="let t">
  <mat-toolbar class="toolbar">
    @if (canGoBack()) {
      <button (click)="goBack()" class="back-button" mat-icon-button type="button">
        <mat-icon svgIcon="arrow_back" />
      </button>
    }
    <a [title]="t('BACK')" class="header-home-link" routerLink="/">
      <picture [class.toolbar-logo-hidden]="!canGoBack()" class="toolbar-logo">
        <source media="(prefers-color-scheme: dark)" srcset="/assets/img/hallohoreca-white.svg" />
        <img alt="HalloHoreca!" src="/assets/img/hallohoreca-black.svg" />
      </picture>
    </a>
    @for (link of menuLinks(); track link.label) {
      <a [routerLink]="link.path" mat-button class="header-navigation-link">{{ t(link.label) }}</a>
    }
    @if (authService.firebaseUser()) {
      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon>person</mat-icon>
      </button>
    } @else {
      <a mat-flat-button routerLink="/login">{{ t('SIGN_IN') }}</a>
    }
    <button [matMenuTriggerFor]="languageMenu" mat-stroked-button>
      {{ languageService.currentLangauge().code.toUpperCase() }}
    </button>

    <button (click)="toggleMenu()" class="navigation-menu-toggle" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>
  <mat-nav-list [@slideInOut]="menuState()" class="navigation-menu">
    @for (link of menuLinks(); track link.label) {
      <a
        mat-list-item
        [routerLink]="link.path"
        (click)="toggleMenu()"
        #rla="routerLinkActive"
        routerLinkActive
        [activated]="link.path ? rla.isActive : false">
        {{ t(link.label) }}
      </a>
    }
  </mat-nav-list>
  <div class="page-content">
    <div class="page-component">
      <router-outlet />
    </div>
    <footer>
      <div class="footer-first-row">
        <picture class="footer-logo">
          <source media="(prefers-color-scheme: dark)" srcset="/assets/img/hallohoreca-white.svg" />
          <img alt="HalloHoreca!" src="/assets/img/hallohoreca-black.svg" />
        </picture>
        <div class="footer-content">
          @for (section of footerSections(); track $index) {
            <div class="footer-section">
              @if (section.title) {
                <h2 class="mat-title-large footer-title">{{ t(section.title) }}</h2>
              }
              @for (link of section.links; track link.label) {
                @if (link.externalLink) {
                  <a class="footer-link" [href]="link.externalLink">{{ t(link.label) }}</a>
                } @else {
                  <a class="footer-link" [routerLink]="link.path">{{ t(link.label) }}</a>
                }
              }
            </div>
          }
        </div>
        <div class="spacer"></div>
      </div>
      <div class="footer-second-row">
        @for (link of socialLinks; track link.url) {
          <a mat-flat-button [href]="link.url" target="_blank">
            <mat-icon [svgIcon]="link.icon" />
            {{ link.label }}
          </a>
        }
      </div>
    </footer>
  </div>
  <mat-menu #profileMenu>
    <a mat-menu-item routerLink="/user/profile">
      <mat-icon>person</mat-icon>
      {{ t('PROFILE') }}
    </a>
    <a mat-menu-item routerLink="/user/payments">
      <mat-icon>payments</mat-icon>
      {{ t('PAYMENTS') }}
    </a>
    <button (click)="authService.signOut()" mat-menu-item>
      <mat-icon>logout</mat-icon>
      {{ t('SIGN_OUT') }}
    </button>
  </mat-menu>
  <mat-menu #languageMenu>
    @for (language of languageService.supportedLanguages; track language.code) {
      <button mat-menu-item (click)="languageService.setLanguage(language.code)">
        {{ language.code.toUpperCase() }} - {{ language.name }}
      </button>
    }
  </mat-menu>
</ng-container>
