import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { CitiesService } from '../api/api/cities.service';
import { LanguageService } from '../locales';
import { ErrorService } from '../shared/error.service';
import { getCountriesInLanguage } from '../shared/i18n';
import {
  getCities,
  getCitiesFailed,
  getCitiesSuccess,
  getCountries,
  getCountriesFailed,
  getCountriesSuccess,
  getRegions,
  getRegionsFailed,
  getRegionsSuccess,
} from './cities.actions';

@Injectable({ providedIn: 'root' })
export class CitiesEffects {
  private citiesService = inject(CitiesService);
  private actions$ = inject(Actions);
  private errorService = inject(ErrorService);
  private languageService = inject(LanguageService);

  getCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCountries),
      switchMap((action) =>
        this.citiesService.listCountries().pipe(
          map((result) =>
            getCountriesSuccess({ payload: getCountriesInLanguage(this.languageService.language(), result.items) }),
          ),
          catchError((error) => this.errorService.handleError(action, getCountriesFailed, error)),
        ),
      ),
    );
  });

  getRegions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getRegions),
      switchMap((action) =>
        this.citiesService.listRegions().pipe(
          map((result) => getRegionsSuccess({ payload: result })),
          catchError((error) => this.errorService.handleError(action, getRegionsFailed, error)),
        ),
      ),
    );
  });

  getCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCities),
      switchMap((action) =>
        this.citiesService.listCities().pipe(
          map((result) => getCitiesSuccess({ payload: result })),
          catchError((error) => this.errorService.handleError(action, getCitiesFailed, error)),
        ),
      ),
    );
  });
}
