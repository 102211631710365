import { AuthGuard } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';

import {
  hasAdminClaims,
  redirectLoggedInHome,
  redirectUnauthorizedToLogin,
  redirectVerifiedEmailToHome,
  verifyEmailGuard,
} from './guards';
import { TranslationKey } from './locales';
import { BACK_URL } from './routing';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, verifyEmailGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadComponent: () => import('./home/home.component'),
  },
  { path: 'news', redirectTo: '', pathMatch: 'full' },
  { path: 'news/:newsId', loadComponent: () => import('./news/news-detail/news-detail.component') },
  { path: 'price-list', loadComponent: () => import('./price-list/price-list.component') },
  { path: 'about', loadComponent: () => import('./about/about.component') },
  { path: 'copyright', loadComponent: () => import('./copyright/copyright.component') },
  { path: 'privacy', loadComponent: () => import('./cookies-privacy-policy/cookies-privacy-policy.component') },
  {
    path: 'notifications',
    loadComponent: () => import('./notifications/set-notification-settings/set-notification-settings.component'),
  },
  {
    path: 'notifications/verify/:notificationId',
    loadComponent: () =>
      import('./notifications/confirm-notification-settings/confirm-notification-settings.component'),
  },
  {
    path: 'notifications/unsubscribe/:notificationId',
    loadComponent: () => import('./notifications/unsubscribe/unsubscribe.component'),
  },
  { path: 'terms', loadComponent: () => import('./terms/terms.component') },
  {
    path: 'search',
    canActivate: [AuthGuard, verifyEmailGuard],
    loadComponent: () => import('./advertisements/advertisements-search/advertisements-search.component'),
  },
  {
    path: 'advertisements/:advertisementId',
    canActivate: [AuthGuard, verifyEmailGuard],
    loadComponent: () => import('./advertisements/advertisement-details/advertisement-details.component'),
    data: {
      [BACK_URL]: '/',
    },
  },
  {
    path: 'verify-email',
    canActivate: [redirectVerifiedEmailToHome],
    loadComponent: () => import('./auth/verify-email/verify-email.component'),
  },
  { path: '__auth/action', loadComponent: () => import('./auth/auth-action/auth-action.component') },
  {
    path: 'register',
    loadComponent: () => import('./auth/register/register.component'),
    canActivate: [AuthGuard],
    title: 'REGISTER' as TranslationKey,
    data: { authGuardPipe: redirectLoggedInHome },
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/sign-in/sign-in.component'),
    canActivate: [AuthGuard],
    title: 'SIGN_IN' as TranslationKey,
    data: { authGuardPipe: redirectLoggedInHome },
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./auth/forgot-password/forgot-password.component'),
    title: 'FORGOT_PASSWORD' as TranslationKey,
  },
  {
    path: 'user',
    canActivate: [AuthGuard, verifyEmailGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'advertisements' },
      {
        path: 'profile',
        title: 'PROFILE' as TranslationKey,
        loadComponent: () => import('./profile/my-profile/my-profile.component'),
        data: {
          [BACK_URL]: '/',
        },
      },
      {
        path: 'advertisements',
        title: 'MY_ADVERTISEMENTS' as TranslationKey,
        loadComponent: () => import('./profile/my-advertisements/my-advertisements.component'),
        data: {
          [BACK_URL]: '/',
        },
      },
      {
        path: 'advertisements/create',
        title: 'PLACE_ADVERTISEMENT' as TranslationKey,
        loadComponent: () => import('./profile/advertisement-details/edit-advertisement/edit-advertisement.component'),
      },
      {
        path: 'advertisements/highlighted',
        title: 'HIGHLIGHTED_ADVERTISEMENTS' as TranslationKey,
        loadComponent: () => import('./profile/highlighted-advertisements/highlighted-advertisements.component'),
      },
      {
        path: 'advertisements/:advertisementId',
        title: 'ADVERTISEMENT_DETAILS' as TranslationKey,
        loadChildren: () => import('./profile/advertisement-details/advertisement-details.routes'),
      },
      {
        path: 'payments',
        title: 'PAYMENTS' as TranslationKey,
        loadComponent: () => import('./profile/payments/payment-list/payment-list.component'),
      },
      {
        path: 'payments/order/:orderId',
        title: 'ORDER' as TranslationKey,
        loadComponent: () => import('./profile/payments/order-details/order-details.component'),
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, verifyEmailGuard],
    data: { authGuardPipe: hasAdminClaims },
    loadChildren: () => import('./admin/admin.routes'),
  },
];
