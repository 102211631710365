import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, computed, inject, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
  RouterState,
} from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { filter, map } from 'rxjs';

import { AuthenticationService } from './auth/authentication.service';
import { LanguageService, TranslationKey } from './locales';
import { BACK_URL } from './routing';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  imports: [
    RouterOutlet,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    TranslocoDirective,
    MatMenuModule,
    MatListModule,
    RouterLinkActive,
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(0)',
        }),
      ),
      state(
        'out',
        style({
          transform: 'translateY(-100%)',
        }),
      ),
      transition('in => out', [animate('300ms cubic-bezier(0, 0, 0, 1)')]),
      transition('out => in', [animate('300ms cubic-bezier(0.3, 0, 1, 1)')]),
    ]),
  ],
})
export class AppComponent {
  title = inject(Title);
  authService = inject(AuthenticationService);
  languageService = inject(LanguageService);
  private router = inject(Router);
  canGoBack = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map((event) => {
        const url = (event as NavigationStart).url;
        return url !== '/' && !url.startsWith('/?');
      }),
    ),
  );
  menuState = signal<'in' | 'out'>('out');
  menuLinks = computed(() => {
    const routes = [
      { path: '', label: 'START_PAGE' as TranslationKey },
      { path: '/search', label: 'TO_TAKE_OVER' as TranslationKey },
      { path: '/user/advertisements', label: 'TO_LEAVE' as TranslationKey },
    ];
    if (this.authService.isAdmin()) {
      routes.push({ path: 'admin', label: 'ADMIN' as TranslationKey });
    }
    return routes;
  });
  footerSections: Signal<{ title?: string; links: { path?: string; externalLink?: string; label: string }[] }[]> =
    computed(() => {
      return [
        { title: 'PAGES' as TranslationKey, links: this.menuLinks() },
        {
          title: 'ABOUT' as TranslationKey,
          links: [
            { path: '/about', label: 'ABOUT_THIS_SITE' as TranslationKey },
            { externalLink: 'mailto:info@hallohoreca.be', label: 'CONTACT_US' as TranslationKey },
            { path: '/price-list', label: 'PRICING' as TranslationKey },
          ],
        },
        {
          links: [
            { path: '/privacy', label: 'COOKIES_AND_PRIVACY' as TranslationKey },
            { path: '/terms', label: 'GENERAL_CONDITIONS' as TranslationKey },
            { path: '/copyright', label: 'COPYRIGHT' as TranslationKey },
          ],
        },
      ];
    });
  socialLinks = [
    { icon: 'instagram', label: 'Instagram', url: 'https://www.instagram.com/hallo_horeca' },
    { icon: 'facebook', label: 'Facebook', url: 'https://www.facebook.com/profile.php?id=61555853762503' },
  ];

  goBack() {
    // By default, go up one route segment. Can be changed by setting the 'backUrl' property inside the route data
    const route = this.findActivatedRoute(this.router.routerState);
    const backUrl: string = route.snapshot.data?.[BACK_URL] ?? '..';
    if (backUrl) {
      this.router.navigate([backUrl], { relativeTo: route });
    }
  }

  private findActivatedRoute(route: RouterState): ActivatedRoute {
    let child = route.root.firstChild!;
    while (child.firstChild) {
      child = child.firstChild;
    }
    return child;
  }

  toggleMenu() {
    this.menuState.update((current) => (current === 'out' ? 'in' : 'out'));
  }
}
